/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { connect } from "react-redux";
import { useState } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import { Field, reduxForm } from "redux-form";
import FormField from "components/FormField";
import FormDateTimePicker from "components/FormDateTimePicker";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import moment from "moment";
import { accessControlCreatePassword } from "store/actions/accessControlActions";

const validate = (values) => {
  const errors = {};
  const requiredFields = ["name", "password", "startDate", "endDate"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (values.password && !/^[0-9]{4,9}$/i.test(values.password)) {
    errors.password = "Can only contain 4 to 9 digits";
  }
  return errors;
};

function AccessControlAddPasswordForm({
  handleSubmit,
  lockId,
  pristine,
  actAccessControlCreatePassword,
  setOpenAddPassword,
  setLockPasswords,
  lockPasswords,
  projectId,
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  const submitData = (data) => {
    const { startDate, endDate, password, name } = data;
    const newEndDate = moment.isMoment(startDate) ? startDate.valueOf() : startDate;
    const newStartDate = moment.isMoment(endDate) ? endDate.valueOf() : endDate;

    actAccessControlCreatePassword({
      ...data,
      lockId,
      projectId,
      endDate: newEndDate,
      startDate: newStartDate,
      cb: (response) => {
        if (!response) {
          setErrorMessage("unknownError");
        } else if (typeof response === "object") {
          console.log(response);
          if (Object.hasOwnProperty.call(response, "errcode")) {
            switch (response.errcode) {
              case -3007:
                setErrorMessage(t("codeAlreadyExists"));
                break;
              default:
                setErrorMessage(t("unknownError"));
                break;
            }
          } else if (Object.hasOwnProperty.call(response, "keyboardPwdId")) {
            setErrorMessage("");
            setLockPasswords([
              {
                lockId,
                keyboardPwdId: response.keyboardPwdId,
                keyboardPwdName: name,
                keyboardPwd: password,
                endDate: newEndDate,
                startDate: newStartDate,
              },
              ...lockPasswords,
            ]);

            setOpenAddPassword(false);
          }
        } else {
          setErrorMessage("");
        }
      },
    });
  };

  return (
    <MDBox my={3}>
      <form onSubmit={handleSubmit(submitData)}>
        <Grid container justifyContent="center" alignItems="center" sx={{ overflow: "visible" }}>
          <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox
              height="4rem"
              bgColor="info"
              variant="gradient"
              coloredShadow="info"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-3}
              mx={10}
              px={2}
            >
              {t("add")} {t("code")}
            </MDBox>
            <MDBox pt={3} pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field name="name" component={FormField} label={t("name")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="password" component={FormField} label={t("code")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="startDate" component={FormDateTimePicker} label={t("startDate")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="endDate" component={FormDateTimePicker} label={t("endDate")} />
                </Grid>
                <Grid item xs={12}>
                  <MDBox display="flex" alignItems="center" flexDirection="column" mt={2} xs={12}>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="dark"
                      size="small"
                      disabled={pristine}
                    >
                      {t("Save")}
                    </MDButton>
                    {errorMessage && (
                      <MDTypography color="error" variant="body2">
                        {errorMessage}
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      </form>
    </MDBox>
  );
}

AccessControlAddPasswordForm.defaultProps = {
  lockId: 0,
  projectId: "",
};

AccessControlAddPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  lockId: PropTypes.number,
  projectId: PropTypes.string,
  actAccessControlCreatePassword: PropTypes.func.isRequired,
  setLockPasswords: PropTypes.func.isRequired,
  setOpenAddPassword: PropTypes.func.isRequired,
  lockPasswords: PropTypes.array.isRequired,
};

const mapStateToProps = () => ({
  initialValues: {
    startDate: moment().valueOf(),
    endDate: moment().add(1, "days"),
  },
});

const mapDispatchToProps = (dispatch) => ({
  actAccessControlCreatePassword: (data) => {
    dispatch(accessControlCreatePassword(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "AccessControlAddPasswordForm", // a unique identifier for this form
    validate,
  })(AccessControlAddPasswordForm)
);
