/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { useTranslation } from "react-i18next";
import { Field, reduxForm, formValueSelector } from "redux-form";

// Settings page components
import FormSelector from "components/FormSelector";
import FormDateTimePicker from "components/FormDateTimePicker";

// NewUser page components

const validate = (values) => {
  const errors = {};
  const requiredFields = ["apartmentId", "startDate", "endDate"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

function ReservationForm(props) {
  const {
    handleSubmit,
    event,
    pristine,
    onSubmit,
    onRemove,
    actionType,
    apartments,
    projectId,
    projects,
    startDate,
    change,
    reservation,
  } = props;

  useEffect(() => {
    const { reservationTime } = reservation;
    const endDate = new Date(startDate.valueOf() + reservationTime * 60 * 1000);
    change("endDate", endDate);
  }, [startDate]);

  const getApartments = () => {
    if (projects) {
      if (Object.hasOwnProperty.call(projects, projectId)) {
        if (Object.hasOwnProperty.call(projects[projectId], "apartments")) {
          return Object.keys(projects[projectId].apartments);
        }
        return [];
      }
      return [];
    }
    return [];
  };

  const submitData = (data) => {
    const { endDate, apartmentId } = data;
    const { name } = apartments[apartmentId];
    onSubmit({
      apartmentId,
      end: endDate.toISOString(),
      start: startDate.toISOString(),
      startDateVal: startDate.valueOf(),
      title: name,
      id: event?.id || uuidv4(),
    });
  };

  const getApartmentsNames = () => {
    if (projects) {
      if (Object.hasOwnProperty.call(projects, projectId)) {
        if (Object.hasOwnProperty.call(projects[projectId], "apartments")) {
          const apartmentIds = Object.keys(projects[projectId].apartments);
          return Object.values(apartments)
            .filter(({ apartmentId }) => apartmentIds.indexOf(apartmentId) >= 0)
            .map(({ apartmentId, name }) => ({
              uid: apartmentId,
              name,
            }));
        }
        return [];
      }
      return [];
    }
    return [];
  };

  const { t } = useTranslation();
  return (
    <MDBox py={3}>
      <form onSubmit={handleSubmit(submitData)}>
        <Grid container justifyContent="center" alignItems="center" sx={{ overflow: "visible" }}>
          <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox
              height="4rem"
              bgColor="info"
              variant="gradient"
              coloredShadow="info"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-3}
              mx={10}
              px={2}
            >
              {t(actionType)} {t("reservation")}
            </MDBox>
            <MDBox pt={3} pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Field
                    name="apartmentId"
                    values={getApartments()}
                    names={getApartmentsNames()}
                    component={FormSelector}
                    label={t("apartment")}
                    disabled={actionType === "edit"}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="startDate" component={FormDateTimePicker} label={t("startDate")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="endDate"
                    disabled
                    component={FormDateTimePicker}
                    label={t("endDate")}
                  />
                </Grid>
                <Grid item xs={12} display="flex" flexDirection="row" justifyContent="center">
                  <MDBox display="flex" justifyContent="center" mt={2} xs={12}>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="dark"
                      size="small"
                      disabled={pristine}
                    >
                      {t("Save")}
                    </MDButton>
                  </MDBox>
                  {actionType === "edit" && (
                    <MDBox display="flex" justifyContent="center" mt={2} ml={2}>
                      <MDButton
                        variant="gradient"
                        color="error"
                        size="small"
                        onClick={() => {
                          onRemove(event.id);
                        }}
                      >
                        {t("Delete")}
                      </MDButton>
                    </MDBox>
                  )}
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      </form>
    </MDBox>
  );
}

ReservationForm.defaultProps = {
  projects: {},
  apartments: {},
  projectId: "",
  event: {},
  startDate: {},
  reservation: {},
};

ReservationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  projects: PropTypes.object,
  event: PropTypes.object,
  startDate: PropTypes.object,
  apartments: PropTypes.object,
  reservation: PropTypes.object,
};

const selector = formValueSelector("ReservationForm");

const mapStateToProps = (state, props) => {
  const { actionType, event, newEvent, reservation } = props;
  const { apartmentsReducer, projectsReducer } = state;

  const { reservationTime } = reservation;

  let startDate = Date.now();
  let endDate = new Date(startDate.valueOf() + reservationTime * 3600 * 1000);
  let newApartmentId = "";

  if (actionType === "add") {
    const { start } = newEvent;
    startDate = start;
    endDate = new Date(start.valueOf() + reservationTime * 3600 * 1000);
  } else {
    const { start, end, extendedProps } = event;
    const { apartmentId } = extendedProps;
    newApartmentId = apartmentId;
    startDate = new Date(start);
    endDate = new Date(end);
  }

  return {
    initialValues: {
      startDate,
      endDate,
      apartmentId: newApartmentId,
    },
    startDate: selector(state, "startDate"),
    apartments: apartmentsReducer,
    projects: projectsReducer,
    reservation,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: "ReservationForm", // a unique identifier for this form
    validate,
  })(ReservationForm)
);
