/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

import { useTranslation } from "react-i18next";

// Images
import bgImage from "assets/images/ondas-onna.jpg";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Logo from "assets/images/logo-ct.png";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyC5RvkP0yh89s-H4ZuBnr-_hJH6tnfst5I",
  authDomain: "onna-btr.firebaseapp.com",
  projectId: "onna-btr",
  storageBucket: "onna-btr.appspot.com",
  messagingSenderId: "853544952403",
  appId: "1:853544952403:web:85be895a55cb0c422fdca6",
  measurementId: "G-KMLWE81PQN",
  databaseURL: "onna-btr-default-rtdb.europe-west1.firebasedatabase.app",
});

// Initialize Firebase
const auth = getAuth(firebaseApp);

function Cover() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const signIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setMessage("");
    } catch (error) {
      switch (error.code) {
        case "auth/wrong-password":
          setMessage(t("invalidPassword"));
          break;
        case "auth/invalid-email":
          setMessage(t("invalidEmail"));
          break;
        case "auth/user-not-found":
          setMessage(t("userNotFound"));
          break;
        default:
          break;
      }
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          pt={4}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img alt="" src={Logo} width="120" />
          <MDTypography variant="h4" fontWeight="medium">
            LIVING
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                placeholder="john@example.com"
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label={t("password")}
                variant="standard"
                fullWidth
                placeholder="************"
                InputLabelProps={{ shrink: true }}
                error={!!message}
                helperText={message}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => {
                  signIn();
                }}
              >
                {t("signIn")}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {`${t("dontHaveAccount")} `}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up/cover"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {t("signUp")}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
