/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import moment from "moment";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CardTitle from "components/CardTitle";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";

// Material Dashboard 2 PRO React examples
import DataTable from "components/DataTable";
import ReservationForm from "layouts/project/components/reservationForm";

function CellDate({ value }) {
  return <span>{value ? moment(value).format("DD/MM/YYYY HH:mm") : ""}</span>;
}

function CellStartEndTime({ value }) {
  return <span>{value ? moment(value).format("HH:mm") : ""}</span>;
}

function AlertDialog({ open, handleClose, handleAgree }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <MDBox
        sx={({ palette: { white, background } }) => ({
          backgroundColor: darkMode ? background.sidenav : white.main,
        })}
      >
        <DialogTitle id="alert-dialog-title">{t("confirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("stepNotUndo")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>{t("cancel")}</MDButton>
          <MDButton onClick={handleAgree} autoFocus>
            {t("OK")}
          </MDButton>
        </DialogActions>
      </MDBox>
    </Dialog>
  );
}

function Reservations({ database, reservations, projectId }) {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [uids, setUids] = useState([]);
  const [actionType, setActionType] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = () => [
    { Header: t("name"), accessor: "name" },
    { Header: t("reservationTime"), accessor: "reservationTime", maxWidth: 50 },
    {
      Header: t("createdAt"),
      accessor: "createdAt",
      Cell: CellDate,
      maxWidth: 100,
    },
    {
      Header: t("startTime"),
      accessor: "startTime",
      Cell: CellStartEndTime,
      maxWidth: 100,
    },
    {
      Header: t("endTime"),
      accessor: "endTime",
      Cell: CellStartEndTime,
      maxWidth: 100,
    },
    {
      Header: t("previousDays"),
      accessor: "previousDays",
      maxWidth: 50,
    },
  ];

  const remove = (data) => {
    const dataToSend = [];
    data.forEach((onnaDevice) => {
      const { original } = onnaDevice;
      dataToSend.push(original);
    });
    setActionType("remove");
    setUids(dataToSend);
    setOpenConfirm(true);
  };

  const onSubmit = (data) => {
    switch (actionType) {
      case "edit":
        database.editReservation(data);
        setOpen(false);
        break;
      case "add":
        database.addReservation(data, projectId);
        setOpen(false);
        break;
      default:
        break;
    }
  };

  const confirm = () => {
    switch (actionType) {
      case "remove":
        database.removeReservations(uids, projectId);
        setActionType("");
        setOpenConfirm(false);
        break;
      default:
        break;
    }
  };

  const add = () => {
    setActionType("add");
    setOpen(true);
  };

  const edit = (indexes) => {
    const reservationsIds = [];
    indexes.forEach((index) => {
      const {
        original: { uid },
      } = index;
      reservationsIds.push(uid);
    });
    setUids(reservationsIds);
    setActionType("edit");
    setOpen(true);
  };

  return (
    <Card>
      <AlertDialog
        open={openConfirm}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        handleAgree={() => {
          confirm();
        }}
      />
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <ReservationForm onSubmit={onSubmit} actionType={actionType} uids={uids} />
        </MDBox>
      </Modal>
      <CardTitle title={t("reservations")} icon="access_time" bgColor="warning" />
      <DataTable
        table={{ columns: columns(), rows: Object.values(reservations) }}
        canSearch
        canSelect
        canEdit
        canAdd
        canActuate
        action={(data) => {
          const uid = data?.original?.uid;
          if (uid) {
            navigate(`/projects/${projectId}/reservation/${uid}`);
          }
        }}
        actionIcon="arrow_forward_ios"
        actionColor="info"
        add={add}
        edit={edit}
        remove={remove}
      />
    </Card>
  );
}

CellStartEndTime.propTypes = {
  value: PropTypes.string,
};

CellStartEndTime.defaultProps = {
  value: "1999/01/01 00:00",
};

CellDate.defaultProps = {
  value: 0,
};

CellDate.propTypes = {
  value: PropTypes.number,
};

Reservations.defaultProps = {
  reservations: {},
  projectId: "",
};

Reservations.propTypes = {
  reservations: PropTypes.object,
  projectId: PropTypes.string,
  database: PropTypes.object.isRequired,
};

AlertDialog.defaultProps = {
  handleAgree: () => {},
};

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAgree: PropTypes.func,
};

export default Reservations;
