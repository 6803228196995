/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { useTranslation } from "react-i18next";
import { Field, reduxForm } from "redux-form";

// Settings page components
import FormDatePicker from "components/FormDatePicker";
import moment from "moment";
import MDTypography from "components/MDTypography";
import { getTrendDataXlsx } from "store/actions/trendsActions";

// NewUser page components

const zeroPad = (num) => String(num).padStart(2, "0");

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "firstName",
    "lastName",
    "email",
    "projectId",
    "apartmentId",
    "password",
    "startDate",
    "endDate",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (values.password?.length > 30) {
    errors.password = "Password is too long. Must be less than 30 characters";
  }
  return errors;
};

function DownloadForm(props) {
  const { handleSubmit, errorMessage, getTrendDataXlsxAct, address } = props;

  const onSubmit = (data) => {
    const { startDate, endDate } = data;
    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);

    try {
      const startDateString = `${startDateObject.getFullYear()}-${zeroPad(
        startDateObject.getMonth() + 1
      )}-${zeroPad(startDateObject.getDate())}`;
      const endDateString = `${endDateObject.getFullYear()}-${zeroPad(
        endDateObject.getMonth() + 1
      )}-${zeroPad(endDateObject.getDate())}`;
      getTrendDataXlsxAct({ address, startDate: startDateString, endDate: endDateString });
    } catch (e) {
      // console.log(e);
    }
  };

  const { t } = useTranslation();
  return (
    <MDBox py={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="center" alignItems="center" sx={{ overflow: "visible" }}>
          <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox
              height="4rem"
              bgColor="info"
              variant="gradient"
              coloredShadow="info"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-3}
              mx={10}
              px={2}
            >
              {t("download")}
            </MDBox>
            <MDBox pt={3} pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field name="startDate" component={FormDatePicker} label={t("startDate")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="endDate" component={FormDatePicker} label={t("endDate")} />
                </Grid>
                <Grid item xs={12}>
                  <MDBox display="flex" alignItems="center" flexDirection="column" mt={2} xs={12}>
                    <MDButton type="submit" variant="gradient" color="dark" size="small">
                      {t("download")}
                    </MDButton>
                    {errorMessage && (
                      <MDTypography color="error" variant="body2">
                        {errorMessage}
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      </form>
    </MDBox>
  );
}

DownloadForm.defaultProps = {
  errorMessage: "",
  address: "",
};

DownloadForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  getTrendDataXlsxAct: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  address: PropTypes.string,
};

const mapStateToProps = () => {
  const now = moment().startOf("day");

  return {
    initialValues: {
      startDate: now.valueOf(),
      endDate: now.add(1, "days").valueOf(),
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTrendDataXlsxAct: (data) => {
    dispatch(getTrendDataXlsx(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "DownloadForm", // a unique identifier for this form
    validate,
  })(DownloadForm)
);
