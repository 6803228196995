/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { memo, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import { getOnnaDevicesState } from "store/actions/onnaDevicesActions";

import { useTranslation } from "react-i18next";

function Dashboard({
  sendGetOnnaDevicesState,
  onnaDevices,
  onnaDevicesData,
  notifications,
  projects,
  users,
  apartments,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    sendGetOnnaDevicesState(Object.keys(onnaDevices));
  }, [JSON.stringify(onnaDevices)]);

  return (
    <DashboardLayout>
      <DashboardNavbar title="dashboard" route="/" />
      <MDBox>
        <MDBox pt={6}>
          <Grid container spacing={3} mb={6}>
            <Grid item xs={12} md={6}>
              <DefaultDoughnutChart
                icon={{ color: "success", component: "hub" }}
                title={t("onnaDevices")}
                description={t("onnaDevicesDetails")}
                chart={{
                  labels: [t("online"), t("offline")],
                  datasets: {
                    backgroundColors: ["info", "primary"],
                    data: onnaDevicesData,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DefaultDoughnutChart
                icon={{ color: "info", component: "notifications" }}
                title={t("notifications")}
                description={t("notificationsDetails")}
                chart={{
                  labels: [t("read"), t("notRead")],
                  datasets: {
                    backgroundColors: ["info", "primary"],
                    data: notifications,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={6}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="group"
                  title={t("clients")}
                  count={users}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon="apartment"
                  title={t("projects")}
                  count={projects}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="house"
                  title={t("apartments")}
                  count={apartments}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

Dashboard.defaultProps = {
  notifications: [0, 0],
  onnaDevices: {},
  onnaDevicesData: [0, 0],
  users: 0,
  projects: 0,
  apartments: 0,
};

// Typechecking props for the DashboardNavbar
Dashboard.propTypes = {
  notifications: PropTypes.array,
  onnaDevices: PropTypes.object,
  onnaDevicesData: PropTypes.array,
  users: PropTypes.number,
  projects: PropTypes.number,
  apartments: PropTypes.number,
  sendGetOnnaDevicesState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    notificationsReducer,
    onnaDevicesReducer,
    clientsReducer,
    projectsReducer,
    apartmentsReducer,
  } = state;
  const connectedDevices = Object.values(onnaDevicesReducer).filter(
    ({ connected }) => connected === true
  ).length;
  const totalDevices = Object.keys(onnaDevicesReducer).length;

  const { badgeNumber, notifications } = notificationsReducer;
  const totalNotifications = Object.keys(notifications).length;

  return {
    notifications: [totalNotifications - badgeNumber, badgeNumber],
    onnaDevicesData: [connectedDevices, totalDevices - connectedDevices],
    onnaDevices: onnaDevicesReducer,
    users: Object.keys(clientsReducer).length,
    projects: Object.keys(projectsReducer).length,
    apartments: Object.keys(apartmentsReducer).length,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendGetOnnaDevicesState: (onnaIds) => {
    dispatch(getOnnaDevicesState(onnaIds));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  memo(Dashboard, (prevProps, nextProps) => {
    if (!(JSON.stringify(prevProps.notifications) === JSON.stringify(nextProps.notifications)))
      return false;
    if (!(JSON.stringify(prevProps.onnaDevicesData) === JSON.stringify(nextProps.onnaDevicesData)))
      return false;
    if (!(JSON.stringify(prevProps.onnaDevices) === JSON.stringify(nextProps.onnaDevices)))
      return false;
    if (!(JSON.stringify(prevProps.users) === JSON.stringify(nextProps.users))) return false;
    if (!(JSON.stringify(prevProps.projects) === JSON.stringify(nextProps.projects))) return false;
    if (!(JSON.stringify(prevProps.apartments) === JSON.stringify(nextProps.apartments)))
      return false;
    return true;
  })
);
