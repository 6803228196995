/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";
import { useState, useRef } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";
// import Checkbox from "@mui/material/Checkbox";
// import { Field, reduxForm, formValueSelector } from "redux-form";
import { Field, reduxForm } from "redux-form";
import FormField from "components/FormField";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { sendCreateMainUser, sendCheckCaptcha } from "store/actions/clientsActions";
import ReCAPTCHA from "react-google-recaptcha";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
// import { CheckBox } from "@mui/icons-material";

// Initialize Firebase
const firebaseApp = initializeApp({
  apiKey: "AIzaSyC5RvkP0yh89s-H4ZuBnr-_hJH6tnfst5I",
  authDomain: "onna-btr.firebaseapp.com",
  projectId: "onna-btr",
  storageBucket: "onna-btr.appspot.com",
  messagingSenderId: "853544952403",
  appId: "1:853544952403:web:85be895a55cb0c422fdca6",
  measurementId: "G-KMLWE81PQN",
  databaseURL: "onna-btr-default-rtdb.europe-west1.firebasedatabase.app",
});

const auth = getAuth(firebaseApp);
// eslint-disable-next-line no-useless-escape
const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!-\/:-@[-`{-~]{8,}$/;

const validate = (values) => {
  const errors = {};
  const requiredFields = ["firstName", "lastName", "email", "company", "password1", "password2"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (values.password1 !== values.password2) {
    errors.password1 = "Passwords are different";
    errors.password2 = "Passwords are different";
  }
  return errors;
};

function CoverForm(props) {
  const { pristine, handleSubmit, sendCreateMainUserAction, sendCheckCaptchaAction } = props;

  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const captchaRef = useRef(null);

  const signUp = async (data) => {
    if (data) {
      const { password1, email } = data;
      if (passwordValidation.test(password1)) {
        try {
          const {
            user: { uid },
          } = await createUserWithEmailAndPassword(auth, email, password1);
          setMessage("");
          sendCreateMainUserAction({ uid, ...data });
        } catch (error) {
          switch (error.code) {
            case "auth/weak-password":
              setMessage(t("weakPassword"));
              break;
            case "auth/invalid-email":
              setMessage(t("invalidEmail"));
              break;
            case "auth/email-already-in-use":
              setMessage(t("emailInUse"));
              break;
            default:
              break;
          }
        }
      } else {
        setMessage(t("weakPassword1"));
      }
    }
  };

  const onSubmit = (data) => {
    const token = captchaRef.current.getValue();
    sendCheckCaptchaAction(token).then((response) => {
      if (Object.hasOwnProperty.call(response, "success")) {
        const { success } = response;
        if (success === true) signUp(data);
      }
    });
    captchaRef.current.reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CoverLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              {t("joinUsToday")}
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              {t("enterEmailAndPassword")}
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox role="form">
              <MDBox mb={2}>
                <Field name="firstName" component={FormField} label={t("First Name")} />
              </MDBox>
              <MDBox mb={2}>
                <Field name="lastName" component={FormField} label={t("Last Name")} />
              </MDBox>
              <MDBox mb={2}>
                <Field name="company" component={FormField} label={t("Company")} />
              </MDBox>
              <MDBox mb={2}>
                <Field name="email" component={FormField} label={t("Email")} />
              </MDBox>
              <MDBox mb={2}>
                <Field name="password1" component={FormField} label={t("Password")} />
              </MDBox>
              <MDBox mb={2}>
                <Field name="password2" component={FormField} label={t("PasswordRepeat")} />
              </MDBox>
              <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
              <MDBox mt={4} mb={1}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  size="small"
                  disabled={pristine}
                  color="info"
                  fullWidth
                  onClick={() => {
                    signUp();
                  }}
                >
                  {t("signUp")}
                </MDButton>
              </MDBox>
              <div style={{ fontSize: "0.75rem", fontWeight: "300", color: "#F44335" }}>
                {message}
              </div>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  {`${t("alreadyHaveAccount")} `}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-in/cover"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    {t("signIn")}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>
    </form>
  );
}

CoverForm.defaultProps = {};

CoverForm.propTypes = {
  sendCheckCaptchaAction: PropTypes.func.isRequired,
  sendCreateMainUserAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  sendCreateMainUserAction: (data) => dispatch(sendCreateMainUser(data)),
  sendCheckCaptchaAction: (token) =>
    new Promise((resolve, reject) => {
      dispatch(sendCheckCaptcha({ token, resolve, reject }));
    }),
});

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: "CoverForm", // a unique identifier for this form
    validate,
  })(CoverForm)
);
