/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";

import { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import { useTranslation } from "react-i18next";

// react-router components
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routesMainUser
import routesMainUser from "routesMainUser";
import routesClient from "routesClient";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { getIdToken, getAuth, onAuthStateChanged } from "firebase/auth";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

// Actions
import { updateMainUser, initClient, initMainUserSocket } from "store/actions/mainUserActions";

import ProtectedRouteMainUser from "ProtectedRouteMainUser";
import ProtectedRouteClient from "ProtectedRouteClient";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInClient from "layouts/authentication/sign-in/client";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ValidationPending from "layouts/authentication/sign-up/validationPending";
import Project from "layouts/project";
import OnnaDevice from "layouts/onnaDevice";
import Apartment from "layouts/apartment/mainUser";
import Reservation from "layouts/reservation";
import Database from "utils/database";
import Notifications from "components/Notifications";
import historyHelper from "historyHelper";

import "./i18n";
import { endSocket } from "store/actions/socketActions";

const database = new Database();

function App({
  updateUserAction,
  initClientAction,
  actInitMainUserSocket,
  user,
  projects,
  sendEndSocket,
}) {
  const { i18n } = useTranslation();
  const { isValidated, isMainUser, isClient } = user;

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  useEffect(() => {
    historyHelper.navigate = navigate;
    historyHelper.location = location;
  }, []);

  useEffect(() => {
    if (isValidated && isMainUser) {
      if (pathname === "/authentication/sign-in/cover") {
        navigate("/", { replace: true });
      }
    } else if (!isValidated && isMainUser) {
      navigate("/authentication/sign-up/validationPending", { replace: true });
    } else if (isValidated && isClient) {
      navigate("/client/apartment", { replace: true });
    }
  }, [isValidated, isMainUser, isClient]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting auth state
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (newUser) => {
      if (newUser) {
        // User is signed in
        getIdToken(newUser).then((token) => {
          const { uid, auth: gotAuth } = newUser;
          database.checkUserValidation(uid).then((validated) => {
            const { validation, mainUser } = validated;
            if (mainUser) {
              if (validation) {
                actInitMainUserSocket({
                  uid,
                  token,
                  cb: (socketCreated) => {
                    if (socketCreated) {
                      database.start(uid);
                      updateUserAction({
                        uid,
                        email: gotAuth?.currentUser?.email,
                        isMainUser: true,
                        isValidated: true,
                        token,
                      });
                    }
                  },
                });
                navigate(pathname, { replace: true });
              } else {
                // User is not validated
                database.stop();
                updateUserAction({
                  uid: "",
                  isMainUser: true,
                  isValidated: false,
                  token,
                });
              }
            } else {
              actInitMainUserSocket({
                uid,
                token,
                cb: (socketCreated) => {
                  if (socketCreated) {
                    initClientAction({ uid, email: gotAuth?.currentUser?.email, token });
                  }
                },
              });
            }
          });
        });
      } else {
        // User is signed out
        database.stop();
        sendEndSocket();
        updateUserAction({ uid: "", isValidated: false, isMainUser: false, isClient: false });
        if (pathname.indexOf("/client/") === 0) {
          navigate("/client/sign-in", { replace: true });
        } else {
          navigate("/authentication/sign-in/cover", { replace: true });
        }
      }
    });
  }, []);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    const { language = "es" } = user;
    moment.locale(language);
    i18n.changeLanguage(language);
  }, [user]);

  const getHidedRoutes = () => (
    <>
      <Route exact path="/authentication/sign-in/cover" element={<SignInCover />} />
      <Route exact path="/authentication/sign-up/cover" element={<SignUpCover />} />
      <Route
        exact
        path="/authentication/sign-up/validationPending"
        element={<ValidationPending />}
      />
      <Route exact path="/projects/:projectId" element={<Project database={database} />} />
      <Route
        exact
        path="/projects/:projectId/onnaDevice/:onnaId"
        element={<OnnaDevice database={database} />}
      />
      <Route
        exact
        path="/projects/:projectId/apartment/:onnaId/:apartmentId"
        element={<Apartment database={database} />}
      />
      <Route
        exact
        path="/projects/:projectId/reservation/:uid"
        element={<Reservation database={database} />}
      />
    </>
  );

  const getHidedClientRoutes = () => (
    <Route exact path="/client/sign-in" element={<SignInClient />} />
  );

  const getRoutesMainUser = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.route) {
        const Component = route.component;
        return (
          <Route
            exact
            path={route.route}
            key={route.key}
            element={
              <ProtectedRouteMainUser user={user} Component={<Component database={database} />} />
            }
          />
        );
      }
      return null;
    });

  const getRoutesClient = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.route) {
        const Component = route.component;
        return (
          <Route
            exact
            path={route.route}
            key={route.key}
            element={
              <ProtectedRouteClient user={user} Component={<Component database={database} />} />
            }
          />
        );
      }
      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {isValidated && layout === "dashboard" ? (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="LIVING"
            routes={isMainUser ? routesMainUser(user, projects) : routesClient(user)}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      ) : (
        <div />
      )}
      {isValidated && layout === "vr" ? <Configurator /> : <div />}
      <Routes>
        {getHidedClientRoutes()}
        {getRoutesMainUser(routesMainUser(user, projects))}
        {getRoutesClient(routesClient(user))}
        {getHidedRoutes()}
      </Routes>
      <Notifications />
    </ThemeProvider>
  );
}

App.defaultProps = {
  user: {},
  projects: {},
};

App.propTypes = {
  updateUserAction: PropTypes.func.isRequired,
  initClientAction: PropTypes.func.isRequired,
  actInitMainUserSocket: PropTypes.func.isRequired,
  sendEndSocket: PropTypes.func.isRequired,
  user: PropTypes.object,
  projects: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.mainUserReducer,
  projects: state.projectsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserAction: (uid) => dispatch(updateMainUser(uid)),
  initClientAction: (uid) => dispatch(initClient(uid)),
  actInitMainUserSocket: (data) => dispatch(initMainUserSocket(data)),
  sendEndSocket: () => {
    dispatch(endSocket());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
