/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import PropTypes from "prop-types";
import { Icon } from "@mui/material";

function Header(props) {
  const { t, user } = props;
  const { company, uid } = user;

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid display="flex" alignItems="center" flexDirection="column" item>
            <MDAvatar
              alt="profile-image"
              src={`https://onnaliving.com/userimagefiles/${uid}.png`}
              size="xl"
              shadow="sm"
              bgColor="light"
            >
              <Icon fontSize="large">person</Icon>
            </MDAvatar>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {company || t("Company")}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="medium">
                {t("Company")}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

Header.defaultProps = {
  t: () => {},
};

Header.propTypes = {
  t: PropTypes.func,
  user: PropTypes.object.isRequired,
};

export default Header;
