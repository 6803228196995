import constants from "../constants";

const { UPDATE_MAIN_USER, INIT_CLIENT, INIT_MAIN_USER_SOCKET, REMOVE_MAIN_USER } = constants;

export const updateMainUser = (data) => ({
  type: UPDATE_MAIN_USER,
  data,
});

export const removeMainUser = () => ({
  type: REMOVE_MAIN_USER,
});

export const initClient = (data) => ({
  type: INIT_CLIENT,
  data,
});

export const initMainUserSocket = (data) => ({
  type: INIT_MAIN_USER_SOCKET,
  data,
});
