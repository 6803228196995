/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";
import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import moment from "moment";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CardTitle from "components/CardTitle";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";
import DataTable from "components/DataTable";
import Chart from "components/ChartVerticalBar";
import { Grid } from "@mui/material";
import { Field, reduxForm } from "redux-form";
import FormSelector from "components/FormSelector";
import { getOccupancyData } from "store/actions/occupancyActions";

function CellOccupied({ value, t }) {
  return <span>{value ? t("occupied").toUpperCase() : t("unoccupied").toUpperCase()}</span>;
}

function CellStartEndTime({ value }) {
  return <span>{value ? moment(value).format("HH:mm") : ""}</span>;
}

const validate = (values) => {
  const errors = {};
  const requiredFields = [];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

function AlertDialog({ open, handleClose, handleAgree }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <MDBox
        sx={({ palette: { white, background } }) => ({
          backgroundColor: darkMode ? background.sidenav : white.main,
        })}
      >
        <DialogTitle id="alert-dialog-title">{t("confirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("stepNotUndo")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>{t("cancel")}</MDButton>
          <MDButton onClick={handleAgree} autoFocus>
            {t("OK")}
          </MDButton>
        </DialogActions>
      </MDBox>
    </Dialog>
  );
}

function Occupancy({
  apartments,
  occupancy,
  project,
  database,
  pristine,
  handleSubmit,
  actGetOccupancyData,
}) {
  const [formatedData, setFormatedData] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const date = new Date();
  const [dateInterval, setDateInterval] = useState({
    startDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
    endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 10),
  });

  const { t } = useTranslation();

  useEffect(() => {
    const { startDate, endDate } = dateInterval;
    if (startDate && endDate) {
      const start = startDate.toISOString();
      const stop = endDate.toISOString();

      const { uid } = project;
      actGetOccupancyData({
        start,
        stop,
        projectId: uid,
        cb: (data) => {
          setTrendData(data);
        },
      });
    }
  }, [JSON.stringify(dateInterval)]);

  const columns = () => [
    { Header: t("name"), accessor: "name" },
    {
      Header: t("occupancy"),
      accessor: "occupancy",
      Cell: (props) => CellOccupied({ ...props, t }),
      maxWidth: 100,
    },
  ];

  useEffect(() => {
    const apartmentsArray = Object.values(apartments);
    const formatedApartments = apartmentsArray.map((apartment) => ({
      ...apartment,
      occupancy: occupancy[apartment?.apartmentId],
    }));
    setFormatedData(formatedApartments);
  }, [apartments, occupancy]);

  const onSubmit = (data) => {
    database.editProject({ ...project, ...data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardTitle title={t("occupancy")} icon="group" bgColor="info" />
        {project?.occupancyConfigured && (
          <MDBox pt={3} pb={3} px={3}>
            <Chart
              newDateInterval={setDateInterval}
              dateInterval={dateInterval}
              trendData={trendData}
              project={project}
            />
          </MDBox>
        )}
        {project?.occupancyConfigured && (
          <MDBox>
            <DataTable
              table={{ columns: columns(), rows: formatedData }}
              canSearch
              actionIcon="arrow_forward_ios"
              actionColor="info"
            />
          </MDBox>
        )}
        <MDBox pt={3} pb={3}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Field
                name="occupancyConfigured"
                values={[true, false]}
                names={[
                  { uid: true, name: t("true") },
                  { uid: false, name: t("false") },
                ]}
                component={FormSelector}
                label={t("configured")}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="center" mt={2} xs={12}>
              <MDBox>
                <MDButton
                  onClick={handleSubmit((data) => {
                    onSubmit(data, "save");
                  })}
                  variant="gradient"
                  color="dark"
                  size="small"
                  name="Save"
                  disabled={pristine}
                >
                  {t("Save")}
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}

CellStartEndTime.propTypes = {
  value: PropTypes.string,
};

CellStartEndTime.defaultProps = {
  value: "1999/01/01 00:00",
};

CellOccupied.defaultProps = {
  value: 0,
  t: () => {},
};

CellOccupied.propTypes = {
  value: PropTypes.number,
  t: PropTypes.func,
};

Occupancy.defaultProps = {
  apartments: {},
  occupancy: {},
  project: {},
};

Occupancy.propTypes = {
  apartments: PropTypes.object,
  occupancy: PropTypes.object,
  actGetOccupancyData: PropTypes.func.isRequired,
  project: PropTypes.object,
  pristine: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  database: PropTypes.object.isRequired,
};

AlertDialog.defaultProps = {
  handleAgree: () => {},
};

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAgree: PropTypes.func,
};

const mapStateToProps = (state, props) => ({
  occupancy: state.occupancyReducer,
  initialValues: props.project,
});

const mapDispatchToProps = (dispatch) => ({
  actGetOccupancyData: (data) => {
    dispatch(getOccupancyData(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "Occupancy", // a unique identifier for this form
    enableReinitialize: true,
    validate,
  })(Occupancy)
);
