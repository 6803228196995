/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import moment from "moment";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CardTitle from "components/CardTitle";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";

// Material Dashboard 2 PRO React examples
import DataTable from "components/DataTable";
import ApartmentForm from "layouts/project/components/apartmentForm";
// import InvoiceForm from "layouts/project/components/invoiceForm";

function CellDate({ value }) {
  return <span>{moment(value).format("DD/MM/YYYY HH:mm")}</span>;
}

const columns = () => {
  const { t } = useTranslation();
  return [
    { Header: t("name"), accessor: "name", width: 20, isSorted: true },
    {
      Header: t("onnaDevice"),
      accessor: "onnaDeviceName",
      width: 20,
    },
    {
      Header: t("createdAt"),
      accessor: "createdAt",
      Cell: CellDate,
      maxWidth: 100,
    },
  ];
};

function AlertDialog({ open, handleClose, handleAgree }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <MDBox
        sx={({ palette: { white, background } }) => ({
          backgroundColor: darkMode ? background.sidenav : white.main,
        })}
      >
        <DialogTitle id="alert-dialog-title">{t("confirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("stepNotUndo")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>{t("cancel")}</MDButton>
          <MDButton onClick={handleAgree} autoFocus>
            {t("OK")}
          </MDButton>
        </DialogActions>
      </MDBox>
    </Dialog>
  );
}

function Apartments({ database, apartments, onnaDevices, projectId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  // const [openInvoice, setOpenInvoice] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [uids, setUids] = useState([]);
  const [actionType, setActionType] = useState("");
  const [formatedData, setFormatedData] = useState([]);

  const onSubmit = (data) => {
    switch (actionType) {
      case "edit":
        database.editApartment(data);
        setOpen(false);
        break;
      case "add":
        database.addApartment(data, projectId);
        setOpen(false);
        break;
      default:
        break;
    }
  };

  const remove = (data) => {
    const dataToSend = [];
    data.forEach((onnaDevice) => {
      const { original } = onnaDevice;
      dataToSend.push(original);
    });
    setActionType("remove");
    setUids(dataToSend);
    setOpenConfirm(true);
  };

  const confirm = () => {
    switch (actionType) {
      case "remove":
        database.removeApartments(uids, projectId);
        setActionType("");
        setOpenConfirm(false);
        break;
      default:
        break;
    }
  };

  const add = () => {
    setActionType("add");
    setOpen(true);
  };

  const edit = (indexes) => {
    const apartmentIndexes = [];
    indexes.forEach((index) => {
      const {
        original: { apartmentId },
      } = index;
      apartmentIndexes.push(apartmentId);
    });
    setUids(apartmentIndexes);
    setActionType("edit");
    setOpen(true);
  };

  useEffect(() => {
    const apartmentsArray = Object.values(apartments);
    const formatedApartments = apartmentsArray.map((apartment) => {
      const { onnaId } = apartment;
      return {
        ...apartment,
        onnaDeviceName: onnaDevices[onnaId]?.name || onnaId,
      };
    });
    setFormatedData(formatedApartments);
  }, [apartments]);

  return (
    <Card>
      <AlertDialog
        open={openConfirm}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        handleAgree={() => {
          confirm();
        }}
      />
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <ApartmentForm onSubmit={onSubmit} actionType={actionType} uids={uids} />
        </MDBox>
      </Modal>
      {/* <Modal
        open={openInvoice}
        onClose={() => {
          setOpenInvoice(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <InvoiceForm onSubmit={onSubmitInvoice} uids={uids} />
        </MDBox>
      </Modal> */}
      <CardTitle title={t("apartments")} icon="house" bgColor="success" />
      <DataTable
        table={{
          columns: columns(),
          rows: formatedData,
        }}
        canSearch
        canSelect
        canEdit
        canAdd
        canActuate
        action={(data) => {
          const onnaId = data?.original?.onnaId;
          const apartmentId = data?.original?.apartmentId;
          if (onnaId && apartmentId) {
            navigate(`/projects/${projectId}/apartment/${onnaId}/${apartmentId}`);
          }
        }}
        actionIcon="arrow_forward_ios"
        actionColor="info"
        add={add}
        edit={edit}
        remove={remove}
      />
    </Card>
  );
}

CellDate.defaultProps = {
  value: 0,
};

CellDate.propTypes = {
  value: PropTypes.number,
};

Apartments.defaultProps = {
  apartments: {},
  onnaDevices: {},
  projectId: "",
};

Apartments.propTypes = {
  apartments: PropTypes.object,
  onnaDevices: PropTypes.object,
  projectId: PropTypes.string,
  database: PropTypes.object.isRequired,
};

AlertDialog.defaultProps = {
  handleAgree: () => {},
};

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAgree: PropTypes.func,
};

export default Apartments;
