import { store } from "store/store";

import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  onValue,
  update,
  off,
  remove,
  query,
  orderByChild,
  get,
  startAt,
  endAt,
} from "firebase/database";
import { v4 as uuidv4 } from "uuid";
import { updateMainUser, removeMainUser } from "store/actions/mainUserActions";
import { newAlertMessage } from "store/actions/globalAlertActions";
import {
  updateOnnaDevices,
  removeOnnaDevices,
  addOnnaDeviceToProject,
  removeOnnaDevicesFromProject,
} from "store/actions/onnaDevicesActions";
import { updateClients, removeClients } from "store/actions/clientsActions";
import { updateApartments, removeApartments } from "store/actions/apartmentsActions";
import { updateProjects, removeProjects } from "store/actions/projectsActions";
// import { updateReservations, removeReservations, updateReservationEvents, removeReservationEvents } from "store/actions/reservationsActions";
import {
  updateReservations,
  removeReservations,
  addReservationEventStore,
  removeReservationEventStore,
  updateReservationEvents,
} from "store/actions/reservationsActions";
import {
  updateNotifications,
  removeNotifications,
  updateBadgeNotifications,
} from "store/actions/notificationsActions";
import { removeOccupancy, updateOccupancy } from "store/actions/occupancyActions";

// Initialize Firebase
const firebaseApp = initializeApp({
  apiKey: "AIzaSyC5RvkP0yh89s-H4ZuBnr-_hJH6tnfst5I",
  authDomain: "onna-btr.firebaseapp.com",
  projectId: "onna-btr",
  storageBucket: "onna-btr.appspot.com",
  messagingSenderId: "853544952403",
  appId: "1:853544952403:web:85be895a55cb0c422fdca6",
  measurementId: "G-KMLWE81PQN",
  databaseURL: "onna-btr-default-rtdb.europe-west1.firebasedatabase.app",
});

class database {
  constructor() {
    this.uid = undefined;
    this.database = getDatabase(firebaseApp);
    this.firebaseApp = firebaseApp;
    this.ref = undefined;
  }

  checkUserValidation(uid) {
    return new Promise((resolve) => {
      try {
        const userValidated = ref(this.database, `/users/${uid}/validated`);
        onValue(userValidated, (snapshot) => {
          if (snapshot.exists()) {
            resolve({ validation: snapshot.val(), mainUser: true });
          } else {
            resolve({ validation: false, mainUser: false });
          }
        });
      } catch {
        resolve({ validation: false, mainUser: false });
      }
    });
  }

  async start(uid) {
    this.uid = uid;
    try {
      onValue(ref(this.database, `/users/${this.uid}`), (snapshot) => {
        if (snapshot.exists()) {
          const {
            onnaDevices,
            clients,
            projects,
            apartments,
            notifications,
            reservations,
            occupancy,
          } = snapshot.val();
          if (!onnaDevices) store.dispatch(removeOnnaDevices());
          if (!projects) store.dispatch(removeProjects());
          if (!clients) store.dispatch(removeClients());
          if (!apartments) store.dispatch(removeApartments());
          if (!notifications) store.dispatch(removeNotifications());
          if (!reservations) store.dispatch(removeReservations());
          if (!occupancy) store.dispatch(removeOccupancy());
        }
      });
      onValue(ref(this.database, `/users/${this.uid}/mainUserData`), (snapshot) => {
        const data = snapshot.val();
        if (Object.hasOwnProperty.call(data, "token")) {
          delete data.token;
        }
        store.dispatch(updateMainUser(data));
      });
      onValue(ref(this.database, `/users/${this.uid}/onnaDevices`), (snapshot) => {
        const data = snapshot.val();
        store.dispatch(updateOnnaDevices(data));
      });
      onValue(ref(this.database, `/users/${this.uid}/projects`), (snapshot) => {
        const data = snapshot.val();
        store.dispatch(updateProjects(data));
      });
      onValue(ref(this.database, `/users/${this.uid}/clients`), (snapshot) => {
        const data = snapshot.val();
        store.dispatch(updateClients(data));
      });
      onValue(ref(this.database, `/users/${this.uid}/apartments`), (snapshot) => {
        const data = snapshot.val();
        store.dispatch(updateApartments(data));
      });
      onValue(ref(this.database, `/users/${this.uid}/reservations`), (snapshot) => {
        const data = snapshot.val();
        store.dispatch(updateReservations(data));
      });
      onValue(ref(this.database, `/users/${this.uid}/occupancy`), (snapshot) => {
        const data = snapshot.val();
        store.dispatch(updateOccupancy(data));
      });
      onValue(ref(this.database, `/users/${this.uid}/notifications`), (snapshot) => {
        const data = snapshot.val();
        store.dispatch(updateNotifications(data));
        if (data) {
          let badgeNumber = 0;
          Object.values(data).forEach((notification) => {
            if (!notification.read) badgeNumber += 1;
          });
          store.dispatch(updateBadgeNotifications(badgeNumber));
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async editMainUser(updates) {
    try {
      await update(ref(this.database, `/users/${this.uid}/mainUserData/`), {
        ...updates,
        token: null,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async removeClients(clients) {
    try {
      const updates = {};
      const promisesUsers = clients.map(async ({ apartmentId, uid }) => {
        updates[uid] = null;
        return remove(
          ref(this.database, `/users/${this.uid}/apartments/${apartmentId}/clients/${uid}`)
        );
      });
      await update(ref(this.database, `/users/${this.uid}/clients/`), updates);
      await Promise.all(promisesUsers);
    } catch (e) {
      console.log(e);
    }
  }

  async addClient(clientData) {
    const { apartmentId, uid } = clientData;
    if (apartmentId) {
      try {
        await update(ref(this.database, `/users/${this.uid}/clients/${uid}`), {
          ...clientData,
          createdAt: Date.now(),
        });
        const child = {};
        child[uid] = true;
        await update(
          ref(this.database, `/users/${this.uid}/apartments/${apartmentId}/clients`),
          child
        );
      } catch (e) {
        console.log(e);
      }
    }
  }

  async editClient(clientData) {
    const { uid } = clientData;
    try {
      await update(ref(this.database, `/users/${this.uid}/clients/${uid}`), clientData);
    } catch (e) {
      console.log(e);
    }
  }

  async removeProjects(uids) {
    try {
      const updates = {};
      uids.forEach((uid) => {
        updates[uid] = null;
      });
      await update(ref(this.database, `/users/${this.uid}/projects/`), updates);
    } catch (e) {
      console.log(e);
    }
  }

  async addProject(projectData) {
    const newUid = uuidv4();
    try {
      await update(ref(this.database, `/users/${this.uid}/projects/${newUid}`), {
        ...projectData,
        uid: newUid,
        createdAt: Date.now(),
      });
    } catch (e) {
      console.log(e);
    }
  }

  async editProject(projectData) {
    const { uid } = projectData;
    try {
      await update(ref(this.database, `/users/${this.uid}/projects/${uid}`), projectData);
    } catch (e) {
      console.log(e);
    }
  }

  async removeReservations(reservationsData, projectId) {
    try {
      const updates = {};
      const promises = reservationsData.map(async ({ uid }) => {
        updates[uid] = null;
        return remove(
          ref(this.database, `/users/${this.uid}/projects/${projectId}/reservations/${uid}`)
        );
      });
      await update(ref(this.database, `/users/${this.uid}/reservations/`), updates);
      await Promise.all(promises);
    } catch (e) {
      console.log(e);
    }
  }

  async addReservation(reservationData, projectId) {
    const newUid = uuidv4();
    try {
      await update(ref(this.database, `/users/${this.uid}/reservations/${newUid}`), {
        ...reservationData,
        uid: newUid,
        createdAt: Date.now(),
      });
      const child = {};
      child[newUid] = true;
      await update(
        ref(this.database, `/users/${this.uid}/projects/${projectId}/reservations/`),
        child
      );
    } catch (e) {
      console.log(e);
    }
  }

  async editReservation(reservationData) {
    const { uid } = reservationData;
    try {
      await update(ref(this.database, `/users/${this.uid}/reservations/${uid}`), reservationData);
    } catch (e) {
      console.log(e);
    }
  }

  addReservationEvent(reservationEventData) {
    const { reservationId, eventId, event } = reservationEventData;
    update(
      ref(this.database, `/users/${this.uid}/reservationEvents/${reservationId}/${eventId}`),
      event
    ).then(() => {
      store.dispatch(addReservationEventStore({ reservationId, eventId, event }));
    });
  }

  removeReservationEvent(data) {
    const { reservationId, eventId } = data;
    remove(ref(this.database, `/users/${this.uid}/reservationEvents/${reservationId}/${eventId}`))
      .then(() => {
        store.dispatch(removeReservationEventStore(data));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getReservationEvents(data) {
    const { reservationId, startDate, endDate } = data;
    const startDateVal = new Date(startDate).valueOf();
    const endDateVal = new Date(endDate).valueOf();

    const newRef = query(
      ref(this.database, `/users/${this.uid}/reservationEvents/${reservationId}/`),
      orderByChild("startDateVal"),
      startAt(startDateVal),
      endAt(endDateVal)
    );
    get(newRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          store.dispatch(updateReservationEvents(snapshot.val()));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async removeOnnaDevices(onnaDevicesData, projectId) {
    try {
      new Promise((resolve, reject) => {
        store.dispatch(
          removeOnnaDevicesFromProject({
            userId: this.uid,
            onnaIds: onnaDevicesData.map(({ onnaId }) => onnaId),
            resolve,
            reject,
          })
        );
      }).then(async () => {
        const updates = {};
        const promisesProject = onnaDevicesData.map(async ({ onnaId }) => {
          updates[onnaId] = null;
          return remove(
            ref(this.database, `/users/${this.uid}/projects/${projectId}/onnaDevices/${onnaId}`)
          );
        });
        await update(ref(this.database, `/users/${this.uid}/onnaDevices/`), updates);
        await Promise.all(promisesProject);
      });
    } catch (e) {
      console.log(e);
    }
  }

  async addOnnaDevice(onnaDeviceData, projectId) {
    const { onnaId } = onnaDeviceData;
    try {
      new Promise((resolve, reject) => {
        store.dispatch(
          addOnnaDeviceToProject({
            userId: this.uid,
            projectId,
            onnaId,
            resolve,
            reject,
          })
        );
      })
        .then(async () => {
          await update(ref(this.database, `/users/${this.uid}/onnaDevices/${onnaId}`), {
            ...onnaDeviceData,
            createdAt: Date.now(),
          });
          const child = {};
          child[onnaId] = true;
          await update(
            ref(this.database, `/users/${this.uid}/projects/${projectId}/onnaDevices/`),
            child
          );
        })
        .catch((error) => {
          switch (error) {
            case "alreadyAssigned":
              store.dispatch(
                newAlertMessage({
                  type: "warning",
                  title: "addOnnaDevice",
                  content: "alreadyAssigned",
                })
              );
              break;
            case "notOpen":
              store.dispatch(
                newAlertMessage({ type: "warning", title: "addOnnaDevice", content: "notOpen" })
              );
              break;
            case "uknownDevice":
              store.dispatch(
                newAlertMessage({
                  type: "error",
                  title: "addOnnaDevice",
                  content: "uknownDevice",
                })
              );
              break;
            default:
              store.dispatch(
                newAlertMessage({ type: "error", title: "addOnnaDevice", content: "uknownError" })
              );
              break;
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  async editOnnaDevice(onnaDeviceData) {
    const { onnaId } = onnaDeviceData;
    try {
      await update(ref(this.database, `/users/${this.uid}/onnaDevices/${onnaId}`), onnaDeviceData);
    } catch (e) {
      console.log(e);
    }
  }

  async importApartments(apartments, projectId) {
    try {
      const imports = {};
      const apartmentIds = {};
      apartments.forEach((apartment) => {
        const { apartmentId } = apartment;
        if (apartmentId) {
          imports[apartmentId] = { ...apartment, createdAt: Date.now() };
          apartmentIds[apartmentId] = true;
        }
      });
      await update(
        ref(this.database, `/users/${this.uid}/projects/${projectId}/apartments/`),
        apartmentIds
      );
      await update(ref(this.database, `/users/${this.uid}/apartments/`), imports);
    } catch (e) {
      console.log(e);
    }
  }

  async addApartment(apartmentData, projectId) {
    const { apartmentId } = apartmentData;
    try {
      await update(ref(this.database, `/users/${this.uid}/apartments/${apartmentId}`), {
        ...apartmentData,
        createdAt: Date.now(),
      });
      const child = {};
      child[apartmentId] = true;
      await update(
        ref(this.database, `/users/${this.uid}/projects/${projectId}/apartments/`),
        child
      );
    } catch (e) {
      console.log(e);
    }
  }

  async editApartment(apartmentData) {
    const { apartmentId } = apartmentData;
    try {
      await update(
        ref(this.database, `/users/${this.uid}/apartments/${apartmentId}`),
        apartmentData
      );
    } catch (e) {
      console.log(e);
    }
  }

  async removeApartments(apartmentsData, projectId) {
    try {
      const updates = {};
      const promises = apartmentsData.map(async ({ apartmentId }) => {
        updates[apartmentId] = null;
        return remove(
          ref(this.database, `/users/${this.uid}/projects/${projectId}/apartments/${apartmentId}`)
        );
      });
      await update(ref(this.database, `/users/${this.uid}/apartments/`), updates);
      await Promise.all(promises);
    } catch (e) {
      console.log(e);
    }
  }

  async removeNotifications(uids) {
    try {
      const updates = {};
      uids.forEach((index) => {
        updates[index] = null;
      });
      await update(ref(this.database, `/users/${this.uid}/notifications/`), updates);
    } catch (e) {
      console.log(e);
    }
  }

  async editNotifications(notificationsData) {
    try {
      await update(ref(this.database, `/users/${this.uid}/notifications/`), notificationsData);
    } catch (e) {
      console.log(e);
    }
  }

  async stop() {
    try {
      off(ref(this.database, `/users/${this.uid}/mainUserData/`));
      off(ref(this.database, `/users/${this.uid}/onnaDevices/`));
      off(ref(this.database, `/users/${this.uid}/projects/`));
      off(ref(this.database, `/users/${this.uid}/clients/`));
      off(ref(this.database, `/users/${this.uid}/apartments/`));
      off(ref(this.database, `/users/${this.uid}/notifications/`));
      off(ref(this.database, `/users/${this.uid}/reservations/`));
      store.dispatch(removeMainUser());
      store.dispatch(removeOnnaDevices());
      store.dispatch(removeProjects());
      store.dispatch(removeClients());
      store.dispatch(removeApartments());
      store.dispatch(removeNotifications());
      store.dispatch(removeReservations());
    } catch (error) {
      console.log(error);
    }
  }
}

export default database;
