/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";
import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import moment from "moment";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CardTitle from "components/CardTitle";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";
import { Grid } from "@mui/material";
import FormField from "components/FormField";
import FormSelector from "components/FormSelector";
import { Field, formValueSelector, reduxForm } from "redux-form";
import MDTypography from "components/MDTypography";
import DataTable from "components/DataTable";
import {
  accessControlCheckCredentials,
  accessControlGetLocks,
  accessControlGetLockPasswords,
  accessControlRemoveLockPassword,
} from "store/actions/accessControlActions";
import AccessControlAddForm from "./accessControlAddForm";
import AccessControlPasswordsForm from "./accessControlPasswordsForm";

// Material Dashboard 2 PRO React examples
// import DataTable from "components/DataTable";
// import ReservationForm from "layouts/project/components/reservationForm";

function CellDate({ value }) {
  return <span>{value ? moment(value).format("DD/MM/YYYY HH:mm") : ""}</span>;
}

function CellStartEndTime({ value }) {
  return <span>{value ? moment(value).format("HH:mm") : ""}</span>;
}

const validate = (values) => {
  const errors = {};
  const requiredFields = [];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

const required = (value) => (value ? undefined : "Required");

function AlertDialog({ open, handleClose, handleAgree }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <MDBox
        sx={({ palette: { white, background } }) => ({
          backgroundColor: darkMode ? background.sidenav : white.main,
        })}
      >
        <DialogTitle id="alert-dialog-title">{t("confirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("stepNotUndo")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>{t("cancel")}</MDButton>
          <MDButton onClick={handleAgree} autoFocus>
            {t("OK")}
          </MDButton>
        </DialogActions>
      </MDBox>
    </Dialog>
  );
}

function CloudApis({
  handleSubmit,
  pristine,
  project,
  projectId,
  mainUserId,
  database,
  actAccessControlCheckCredentials,
  actAccessControlGetLocks,
  actAccessControlGetLockPasswords,
  actAccessControlRemoveLockPassword,
  accessControlConfigured,
  accessControlSystem,
}) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [testStatus, setTestStatus] = useState("Stopped");
  const [openAddLocks, setOpenAddLocks] = useState(false);
  const [openEditLock, setOpenEditLock] = useState(false);
  const [locksList, setLocksList] = useState({});
  const [locksListDisable, setLocksListDisable] = useState(true);
  const [commonLocksList, commonSetLocksList] = useState({});
  const [commonLocksListDisable, commonSetLocksListDisable] = useState(true);
  const [lockIds, setLockIds] = useState([]);
  const [lockEditId, setLockEditId] = useState(0);
  const [actionType, setActionType] = useState("");

  const { cloudApis } = project;

  useEffect(() => {
    setLocksList(cloudApis?.accessControl?.locksList || {});
  }, [JSON.stringify(cloudApis?.accessControl?.locksList)]);

  useEffect(() => {
    commonSetLocksList(cloudApis?.accessControl?.commonLocksList || {});
  }, [JSON.stringify(cloudApis?.accessControl?.commonLocksList)]);

  const { t } = useTranslation();

  const onSubmit = (data, type) => {
    if (type === "save") {
      const newProjectData = { ...project };
      if (cloudApis) {
        if (Object.hasOwnProperty.call(cloudApis, "accessControl")) {
          const { accessControl } = cloudApis;
          const newAccessControlData = { ...accessControl, ...data };
          newProjectData.cloudApis.accessControl = newAccessControlData;
          database.editProject({ ...newProjectData });
        }
      }
    } else if (type === "test") {
      const { accessControlName, accessControlPassword } = data;
      if (
        accessControlConfigured === true &&
        accessControlName &&
        accessControlPassword &&
        accessControlSystem === "omnitec"
      ) {
        setTestStatus("Pending...");
        actAccessControlCheckCredentials({
          userData: { ...data, projectId, mainUserId },
          cb: (response) => {
            if (response) {
              setTestStatus("Success");
            } else {
              setTestStatus("Fail");
            }
          },
        });
      }
    }
  };

  const onSubmitLocksList = () => {
    const newProjectData = { ...project };
    if (cloudApis) {
      if (Object.hasOwnProperty.call(cloudApis, "accessControl")) {
        const { accessControl } = cloudApis;
        const newAccessControlData = { ...accessControl, locksList };
        newProjectData.cloudApis.accessControl = newAccessControlData;
        database.editProject({ ...newProjectData });
        setLocksListDisable(true);
      }
    }
  };

  const commonOnSubmitLocksList = () => {
    const newProjectData = { ...project };
    if (cloudApis) {
      if (Object.hasOwnProperty.call(cloudApis, "accessControl")) {
        const { accessControl } = cloudApis;
        const newAccessControlData = { ...accessControl, commonLocksList };
        newProjectData.cloudApis.accessControl = newAccessControlData;
        database.editProject({ ...newProjectData });
        commonSetLocksListDisable(true);
      }
    }
  };

  const columns = () => [
    { Header: t("name"), accessor: "lockAlias" },
    { Header: "LOCK", accessor: "lockName" },
    { Header: "ID", accessor: "lockId" },
    {
      Header: t("createdAt"),
      accessor: "createdAt",
      Cell: CellDate,
      maxWidth: 100,
    },
  ];

  const removeLock = (data) => {
    const dataToSend = [];
    data.forEach((onnaDevice) => {
      const { original } = onnaDevice;
      const { lockId } = original;
      dataToSend.push(lockId);
    });
    setActionType("removeLocks");
    setLockIds(dataToSend);
    setOpenConfirm(true);
    setLocksListDisable(false);
  };

  const commonRemoveLock = (data) => {
    const dataToSend = [];
    data.forEach((onnaDevice) => {
      const { original } = onnaDevice;
      const { lockId } = original;
      dataToSend.push(lockId);
    });
    setActionType("commonRemoveLocks");
    setLockIds(dataToSend);
    setOpenConfirm(true);
    commonSetLocksListDisable(false);
  };

  const confirm = () => {
    switch (actionType) {
      case "removeLocks": {
        setActionType("");
        setOpenConfirm(false);
        const newLocksList = { ...locksList };
        lockIds.forEach((lockId) => {
          if (Object.hasOwnProperty.call(locksList, lockId)) {
            delete newLocksList[lockId];
          }
        });
        setLocksList(newLocksList);
        break;
      }
      case "commonRemoveLocks": {
        setActionType("");
        setOpenConfirm(false);
        const newLocksList = { ...commonLocksList };
        lockIds.forEach((lockId) => {
          if (Object.hasOwnProperty.call(commonLocksList, lockId)) {
            delete newLocksList[lockId];
          }
        });
        commonSetLocksList(newLocksList);
        break;
      }
      default:
        break;
    }
  };

  const addLock = () => {
    setActionType("addLocks");
    setOpenAddLocks(true);
  };

  const commonAddLock = () => {
    setActionType("commonAddLocks");
    setOpenAddLocks(true);
  };

  return (
    <form>
      <Card>
        <AlertDialog
          open={openConfirm}
          handleClose={() => {
            setOpenConfirm(false);
          }}
          handleAgree={() => {
            confirm();
          }}
        />
        <Modal
          open={openAddLocks}
          onClose={() => {
            setOpenAddLocks(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflow: "scroll" }}
        >
          <MDBox
            mt={3}
            sx={12}
            sm={8}
            style={{
              position: "absolute",
              width: "65vw",
              left: "50%",
              transform: "translate(-50%, 0%)",
            }}
          >
            <AccessControlAddForm
              getLocks={actAccessControlGetLocks}
              projectId={projectId}
              onSubmit={(data) => {
                switch (actionType) {
                  case "addLocks": {
                    const newLocksList = { ...locksList };
                    data.forEach((lock) => {
                      const { original } = lock;
                      const { lockId } = original;
                      newLocksList[lockId] = { ...original, createdAt: Date.now() };
                    });
                    setLocksList(newLocksList);
                    setLocksListDisable(false);
                    break;
                  }
                  case "commonAddLocks": {
                    const newLocksList = { ...locksList };
                    data.forEach((lock) => {
                      const { original } = lock;
                      const { lockId } = original;
                      newLocksList[lockId] = { ...original, createdAt: Date.now() };
                    });
                    commonSetLocksList(newLocksList);
                    commonSetLocksListDisable(false);
                    break;
                  }
                  default:
                    break;
                }
                setOpenAddLocks(false);
              }}
            />
          </MDBox>
        </Modal>
        <Modal
          open={openEditLock}
          onClose={() => {
            setOpenEditLock(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflow: "scroll" }}
        >
          <MDBox
            mt={3}
            sx={12}
            sm={8}
            style={{
              position: "absolute",
              width: "65vw",
              left: "50%",
              transform: "translate(-50%, 0%)",
            }}
          >
            <AccessControlPasswordsForm
              lockEditId={lockEditId}
              getLockPasswords={actAccessControlGetLockPasswords}
              projectId={projectId}
              removeLockPassword={actAccessControlRemoveLockPassword}
              onSubmit={() => {}}
            />
          </MDBox>
        </Modal>
        <CardTitle title={t("accessControl")} icon="key" bgColor="success" />
        <MDBox pt={3} pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Field
                name="accessControlConfigured"
                values={[true, false]}
                names={[
                  { uid: true, name: t("true") },
                  { uid: false, name: t("false") },
                ]}
                component={FormSelector}
                label={t("configured")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="accessControlSystem"
                values={["omnitec", "salto"]}
                names={[
                  { uid: "omnitec", name: "Onna Access" },
                  { uid: "salto", name: "Salto KS" },
                ]}
                component={FormSelector}
                label={t("system")}
              />
            </Grid>
            {accessControlConfigured && (
              <Grid item xs={12} sm={6}>
                <Field
                  name="accessControlName"
                  component={FormField}
                  label={`${t("user")} ${t("name")}`}
                  validate={accessControlConfigured ? required : undefined}
                />
              </Grid>
            )}
            {accessControlConfigured && (
              <Grid item xs={12} sm={6}>
                <Field
                  name="accessControlPassword"
                  component={FormField}
                  label={t("Password")}
                  validate={accessControlConfigured ? required : undefined}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="center" mt={2} xs={12}>
              <MDBox pr={2}>
                <MDButton
                  onClick={handleSubmit((data) => {
                    onSubmit(data, "save");
                  })}
                  variant="gradient"
                  color="dark"
                  size="small"
                  name="Save"
                  disabled={pristine}
                >
                  {t("Save")}
                </MDButton>
              </MDBox>
              <MDBox>
                <MDButton
                  onClick={handleSubmit((data) => {
                    onSubmit(data, "test");
                  })}
                  variant="gradient"
                  color="dark"
                  size="small"
                  name="Test"
                >
                  {t("Test")}
                </MDButton>
              </MDBox>
            </MDBox>
            <MDBox display="flex" justifyContent="center" mt={2} xs={12}>
              <MDTypography
                display="inline"
                variant="button"
                fontWeight="regular"
                color={testStatus === "Success" ? "success" : "text"}
              >
                {`Test: ${testStatus}`}
              </MDTypography>
            </MDBox>
          </Grid>
        </MDBox>
        <CardTitle title={t("roomsLocksList")} icon="meeting_room" bgColor="info" />
        <DataTable
          table={{ columns: columns(), rows: Object.values(locksList) }}
          canSearch
          canSelect
          canAdd
          canEdit
          canActuate
          action={(data) => {
            const { original } = data;
            setLockEditId(original?.lockId);
            setOpenEditLock(true);
          }}
          actionIcon="arrow_forward_ios"
          actionColor="info"
          add={addLock}
          remove={removeLock}
          edit={() => {}}
        />
        <Grid item xs={12}>
          <MDBox display="flex" justifyContent="center" mb={4} xs={12}>
            <MDButton
              onClick={() => [onSubmitLocksList()]}
              variant="gradient"
              color="dark"
              size="small"
              name="Save"
              disabled={locksListDisable}
            >
              {`${t("Save")} ${t("roomsLocksList")}`}
            </MDButton>
          </MDBox>
        </Grid>
        <CardTitle title={t("commonLocksList")} icon="meeting_room" bgColor="info" />
        <DataTable
          table={{ columns: columns(), rows: Object.values(commonLocksList) }}
          canSearch
          canSelect
          canAdd
          canEdit
          canActuate
          action={(data) => {
            const { original } = data;
            setLockEditId(original?.lockId);
            setOpenEditLock(true);
          }}
          actionIcon="arrow_forward_ios"
          actionColor="info"
          add={commonAddLock}
          remove={commonRemoveLock}
          edit={() => {}}
        />
        <Grid item xs={12}>
          <MDBox display="flex" justifyContent="center" mb={4} xs={12}>
            <MDButton
              onClick={() => [commonOnSubmitLocksList()]}
              variant="gradient"
              color="dark"
              size="small"
              name="Save"
              disabled={commonLocksListDisable}
            >
              {`${t("Save")} ${t("commonLocksList")}`}
            </MDButton>
          </MDBox>
        </Grid>
      </Card>
    </form>
  );
}

CellStartEndTime.propTypes = {
  value: PropTypes.string,
};

CellStartEndTime.defaultProps = {
  value: "1999/01/01 00:00",
};

CellDate.defaultProps = {
  value: 0,
};

CellDate.propTypes = {
  value: PropTypes.number,
};

CloudApis.defaultProps = {
  project: {},
  projectId: "",
  mainUserId: "",
  accessControlSystem: "",
  accessControlConfigured: false,
};

CloudApis.propTypes = {
  project: PropTypes.object,
  projectId: PropTypes.string,
  mainUserId: PropTypes.string,
  database: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  accessControlSystem: PropTypes.string,
  accessControlConfigured: PropTypes.bool,
  actAccessControlCheckCredentials: PropTypes.func.isRequired,
  actAccessControlGetLocks: PropTypes.func.isRequired,
  actAccessControlGetLockPasswords: PropTypes.func.isRequired,
  actAccessControlRemoveLockPassword: PropTypes.func.isRequired,
};

AlertDialog.defaultProps = {
  handleAgree: () => {},
};

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAgree: PropTypes.func,
};

const selector = formValueSelector("CloudApis");

const mapStateToProps = (state, props) => {
  const { mainUserReducer } = state;
  const { project, projectId } = props;
  const { cloudApis } = project;
  let accessControlData = {};
  if (cloudApis) {
    if (Object.hasOwnProperty.call(cloudApis, "accessControl")) {
      const { accessControl } = cloudApis;
      accessControlData = accessControl;
    }
  }
  return {
    initialValues: accessControlData,
    projectId,
    mainUserId: mainUserReducer?.uid,
    accessControlSystem: selector(state, "accessControlSystem"),
    accessControlConfigured: selector(state, "accessControlConfigured"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actAccessControlCheckCredentials: (data) => {
    dispatch(accessControlCheckCredentials(data));
  },
  actAccessControlGetLocks: (data) => {
    dispatch(accessControlGetLocks(data));
  },
  actAccessControlGetLockPasswords: (data) => {
    dispatch(accessControlGetLockPasswords(data));
  },
  actAccessControlRemoveLockPassword: (data) => {
    dispatch(accessControlRemoveLockPassword(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "CloudApis", // a unique identifier for this form
    enableReinitialize: true,
    validate,
  })(CloudApis)
);
